<template>
    <v-card color="white" class="elevation-2 fill-height mt-1 mt-md-1" v-if="user.lat">
        <v-card-text class="py-0 pb-3 pb-md-1 pt-lg-3 px-0 secondary--text">
            <v-row dense class="align-center">
                <v-col cols="12" lg="12" class="text-center" v-if="user.show_geo">
                    <div class="px-3">
                        <iframe
                            width="100%"
                            height="180"
                            style="border:0"
                            class="elevation-2"
                            loading="lazy"
                            allowfullscreen
                            :src="'https://www.google.com/maps/embed/v1/place?key=AIzaSyBmlVSyO63l-PzJI0EH3GjhavC0fD2wR5g&zoom=14&q='+user.lat+','+user.lng"
                        >
                        </iframe>
                    </div>
                </v-col>
                <v-col cols="12" lg="12" class="py-0" v-if="user.show_geo">
                    <v-list class="pt-0 pb-0 no-truncate">
                        <v-list-item class="pl-1" @click="$router.push('/app/account'); $appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Dashboard: Geo: Update Geo Clicked' }});">
                            <v-list-item-avatar>
                                <v-avatar>
                                    <v-icon class="pl-1" color="primary">fa fa-map-marker-alt</v-icon>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium secondary--text">Last Login Location</v-list-item-title>
                                <v-list-item-subtitle>Only displayed if you fail to check in.</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action class="align-self-center">
                                <v-icon small color="#999">fa fa-cog</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-col>
                <v-col cols="12" lg="12" class="text-center" v-if="!user.show_geo">
                    <p class="my-5"><v-icon color="primary" x-large>fa fa-map-marker-alt</v-icon></p>
                    <p class="subtitle-1 mb-2 lh-15">You are not sharing your latest login location.</p>
                    <p class="body-2 px-md-2">Your last login location can only be seen by your emergency contacts if you fail to check in.</p>
                    <p><v-btn color="primary" :loading="saving" @click.native="updateShowGeo">Share My Location</v-btn></p>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>

import { mapState } from 'vuex'

export default {

  name: 'UserDashboardUserGeo',

    props: {
    },
    computed: {
        ...mapState(['user'])
    },
    data: () => ({
        saving: false,
    }),
    methods: {
        initData(){
        },
        updateShowGeo(){
            this.saving = true;
            let request = {
                endpoint: '/profile', 
                method: 'post',
                data: {
                    show_geo: true,
                    _method: 'put'
                },
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
                    this.$eventHub.$emit('refreshUser');
                    this.$eventHub.$emit('snackBar', {
                        text: 'Your location sharing has been enabled!'
                    });
                    this.action = null;
                }else{
                    this.handleErrorResponse(res);
                }
            }).finally(()=>{
                this.saving = false;
            });
        },
    },
    mounted(){
        this.initData();
    },
    watch: {
    }
}
</script>
